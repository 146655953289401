import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import productStyles from './product.module.scss';
import Contact from '../components/contact';
import Head from '../components/head';
import Layout from '../components/layout';
import ProductCarousel from '../components/product-carousel';
import ProductSectionFive from '../components/product-section/product-section-five';
import ProductSectionFour from '../components/product-section/product-section-four';
import ProductSectionOne from '../components/product-section/product-section-one';
import ProductSectionSeven from '../components/product-section/product-section-seven';
import ProductSectionSix from '../components/product-section/product-section-six';
import ProductSectionThree from '../components/product-section/product-section-three';
import ProductSectionTwo from '../components/product-section/product-section-two';
import ProductSectionEight from '../components/product-section/product-section-eight';
import { useInView } from 'react-intersection-observer';

export const query = graphql`
  query($hiddenProductPath: String!) {
    contentfulHiddenProduct(hiddenProductPath: { eq: $hiddenProductPath }) {
      name
      model
      hiddenProductPath
      image {
        file {
          url
          fileName
        }
      }
      headline
      headlineOrange
      productDimensions
      awards {
        file {
          url
          fileName
        }
      }
      productSection {
        sectionName
        header
        paragraph {
          paragraph
        }
        quote
        quoteAuthor
        note1 {
          note1
        }
        note2 {
          note2
        }
        images {
          file {
            url
            fileName
          }
        }
        layoutType
        fullBleedQuote
        fullBleedQuoteAuthor
      }
    }
  }
`;

type DataProps = {
  contentfulHiddenProduct: {
    name: string;
    model: string;
    image: {
      file: {
        url: string;
        fileName: string;
      };
    };
    headline: string;
    headlineOrange: string;
    productDimensions: string;
    awards: {
      file: {
        url: string;
        fileName: string;
      };
    };
    productSection: [
      {
        sectionName: string;
        header: string;
        paragraph: {
          paragraph: string;
        };
        quote: string;
        quoteAuthor: string;
        note1: {
          note1: string;
        };
        note2: {
          note2: string;
        };
        images: [
          {
            file: {
              url: string;
              fileName: string;
            };
          },
        ];
        layoutType: number;
      },
    ];
    fullBleedQuote: string;
    fullBleedQuoteAuthor: string;
  };
};

const HiddenProduct: React.FC<PageProps<DataProps>> = ({ data }) => {
  const options = { threshold: 0.5 };
  const [titleRef, titleInView, titleEntry] = useInView(options);
  const [imageRef, imageInView, imageEntry] = useInView(options);

  return (
    <Layout>
      <div className="products">
        <Grid>
          <Row className="head">
            <Col xs={12} md={4} className="vert-center">
              <Head title={data?.contentfulHiddenProduct?.name ?? ''} />
              <div ref={titleRef}>
                <div
                  className={
                    titleInView
                      ? productStyles.slideUShow
                      : productStyles.slideUHide
                  }
                >
                  <h2>
                    {data?.contentfulHiddenProduct?.name ?? ''}{' '}
                    <strong>
                      {data?.contentfulHiddenProduct?.model ?? ''}
                    </strong>
                  </h2>
                  <h1>
                    {data?.contentfulHiddenProduct?.headline ?? ''}{' '}
                    <span>
                      {data?.contentfulHiddenProduct?.headlineOrange ?? ''}
                    </span>
                  </h1>
                  <h4>
                    {data?.contentfulHiddenProduct?.productDimensions ?? ''}
                  </h4>
                  <img
                    src={data?.contentfulHiddenProduct?.awards?.file?.url ?? ''}
                    alt={
                      data?.contentfulHiddenProduct?.awards?.file?.fileName ??
                      ''
                    }
                    width="36"
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} mdOffset={2}>
              <div ref={imageRef}>
                <div
                  className={
                    imageInView
                      ? productStyles.fadeShow
                      : productStyles.fadeHide
                  }
                >
                  <img
                    src={data?.contentfulHiddenProduct?.image?.file?.url ?? ''}
                    alt={
                      data?.contentfulHiddenProduct?.image?.file?.fileName ?? ''
                    }
                    className="stretchImage"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
        {data?.contentfulHiddenProduct?.productSection?.map((section: any) => {
          if (section.layoutType === 1)
            return <ProductSectionOne productSection={section} />;
          else if (section.layoutType === 2)
            return <ProductSectionTwo productSection={section} />;
          else if (section.layoutType === 3)
            return <ProductSectionThree productSection={section} />;
          else if (section.layoutType === 4)
            return <ProductSectionFour productSection={section} />;
          else if (section.layoutType === 5)
            return <ProductSectionFive productSection={section} />;
          else if (section.layoutType === 6)
            return <ProductSectionSix productSection={section} />;
          else if (section.layoutType === 7)
            return <ProductSectionSeven productSection={section} />;
          else if (section.layoutType === 8)
            return <ProductSectionEight productSection={section} />;
        })}
        <Grid>
          <Row>
            <Col md={12}>
              <ProductCarousel />
            </Col>
          </Row>
        </Grid>
        <div className="grey">
          <Contact />
        </div>
      </div>
    </Layout>
  );
};

export default HiddenProduct;
